import { formatInvoceType } from "@/utils/type";
export const rowKey = "id";

export const columns = [
  {
    title: "订单号",
    dataIndex: "order_no",
    align: "center",
    width: 160,
    slots: { customRender: "order_no" },
  },
  {
    title: "申请时间",
    dataIndex: "apply_time",
    align: "center",
  },
  {
    title: "开票日期",
    dataIndex: "audit_time",
    align: "center",
  },
  {
    title: "负责网点",
    dataIndex: "actual_sale_ascription_outlets",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "audit_status",
    align: "center",
    width: "9%",
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatInvoceType(val),
  },
  {
    title: "开票人",
    dataIndex: "reviewer",
    align: "center",
    width: "15%",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 110,
    slots: { customRender: "operation" },
  },
];

export const columns_invoice = [
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
  },
  {
    title: "商品分类",
    dataIndex: "goods_type",
    align: "center",
    width: "80px",
    slots: {
      customRender: "type",
    },
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    width: "80px",
  },
  {
    title: "订单金额",
    dataIndex: "order_amount",
    align: "center",
    width: "80px",
  },
];

export const columns_express = [
  {
    title: "运单号",
    dataIndex: "waybill_no",
    align: "center",
  },
  {
    title: "旺店通仓库编号",
    dataIndex: "warehouse_no",
    align: "center",
  },
  {
    title: "仓库财务账户",
    dataIndex: "financial_account_name",
    align: "center",
  },
];

export const goodsType = {
  1: "软点",
  2: "西点",
  3: "蛋糕",
};

export function formatType(val) {
  return goodsType[val];
}

export default {
  rowKey,
  columns,
  columns_invoice,
  columns_express,
  goodsType,
};
