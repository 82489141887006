import ajax from "@/utils/ajax.js";

/**
 * 获取发票列表
 * @param {*} params
 * @returns
 */
export function getInvoiceApplyList(params) {
  return ajax.post("/InvoiceApply/getList", params);
}

/**
 * 获取发票详情
 * @param {*} params
 * @returns
 */
export function getInvoiceApplyDesc(params) {
  return ajax.post("/InvoiceApply/detail", params);
}

/**
 * 开发票
 * @param {*} params
 * @returns
 */
export function openInvoce(params) {
  return ajax.post("/InvoiceApply/open", params);
}
